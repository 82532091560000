<template>
  <div class="return-truck-form">
    <v-dialog
        :value="dialog"
        width="900"
        persistent
        transition="dialog-bottom-transition"
        scrollable
    >
      <v-card v-if="isAdmin" tile>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="linde-font px-3"
          >Return Truck
            <span v-if="!selectedReturnTruck.id">erstellen</span>
            <span v-else>bearbeiten</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form
              id="return-truck-form"
              v-model="valid"
              ref="form"
              lazy-validation
          >
            <!-- Line type & construction type & serial number & mast type -->
            <v-layout>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-combobox
                    clearable
                    :items="constructionTypes"
                    v-model="selectedReturnTruck.cType"
                    label="Typ"
                    @input.native="e => selectedReturnTruck.cType = e.target.value"
                ></v-combobox>
              </v-flex>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-text-field
                    v-model="selectedReturnTruck.chassisNumber"
                    label="Fahrgestell-Nr.*"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-flex>
            </v-layout>
            <!-- Line construction year & load capacity & operating hours & price -->
            <v-layout>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-select
                    :items="generateConstructionYearSpan()"
                    v-model="constructionYear"
                    label="Baujahr"
                ></v-select>
              </v-flex>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-text-field
                    v-model="selectedReturnTruck.operatingHours"
                    label="Betriebsstunden (h)"
                ></v-text-field>
              </v-flex>
              <v-flex class="mr-3" md4 sm6 xs12>
                <v-combobox
                    :items="manufacturers"
                    v-model="selectedReturnTruck.manufacturer"
                    label="Hersteller"
                    @input.native="e => selectedReturnTruck.manufacturer = e.target.value"
                ></v-combobox>
              </v-flex>
            </v-layout>
            <!-- Line device status & location -->
            <v-layout>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-select
                    :items="statusItems"
                    v-model="selectedReturnTruck.status"
                    label="Status"
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line availability & guarantee -->
            <!-- Line images real -->
            <v-layout>
              <v-flex md12>
                <!-- multiple select of real images -->
                <v-layout v-if="selectedReturnTruck.images" row wrap>
                  <v-flex
                      md2
                      v-for="(image, index) of selectedReturnTruck.images"
                      :key="image.imageId"
                  >
                    <v-layout justify-space-between class="mb-2" row wrap>
                      <v-flex class="pl-5" md2 v-if="image.image ">
                        <v-img
                            v-if="!selectedReturnTruck.id || !image.returnTruckId"
                            class="mb-2"
                            :src="image.url"
                            width="55"
                            height="55"
                        >
                        </v-img>
                        <v-img
                            v-else
                            class="mb-2"
                            :src="appendBaseUrl(image.image)"
                            width="55"
                            height="55"
                        ></v-img>
                      </v-flex>
                    </v-layout>
                    <v-layout class="text--left" row wrap>
                      <v-flex md3>
                        <v-btn
                            class="mt-0 pt-0"
                            small
                            icon
                            flat
                            @click="
                            removeImageFromList(
                              image.imageId ? image.imageId : '',
                              index,
                              image
                            )
                          "
                        >
                          <v-icon color="primary" small>delete</v-icon>
                        </v-btn>
                      </v-flex>
                      <v-flex class="my-0 py-0 pt-1" md9>
                        {{ formatImageName(image.image) }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <v-btn
                    color="primary"
                    class="normalize-font"
                    flat
                    @click="pickFile(false)"
                >
                  <v-icon small>attach_file</v-icon>
                  Bilder hinzufügen
                </v-btn>
                <input
                    type="file"
                    style="display: none"
                    ref="images"
                    accept="image/*"
                    @change="onMultipleFilesPicked"
                    multiple
                />
              </v-flex>
            </v-layout>
            <v-layout v-if="selectedReturnTruck.id">
              <v-flex md12>
                <v-btn
                    color="primary"
                    class="normalize-font"
                    flat
                    @click="uploadFiles"
                >
                  <v-icon small>attach_file</v-icon>
                  Konfigurationsliste {{
                    configurations && configurations.length > 0 ? "ersetzen" : "hinzufügen"
                  }}
                </v-btn>
                <input
                    type="file"
                    style="display: none"
                    ref="text"
                    @change="onFileChange"
                />
                {{ uploadStatus }}
              </v-flex>
            </v-layout>
            <v-divider style="margin-bottom: 10px"
                       v-if="configurations && configurations.length > 0"></v-divider>
            <v-layout v-if="configurations && configurations.length > 0">
              <v-flex md12>
                Konfigurationsliste
              </v-flex>
            </v-layout>
            {{ pagination }} - {{ itemsPerPage }} - {{ configurations.length }}
            <v-layout v-if="configurations && configurations.length > 0">
              <v-flex md12>

                <v-data-table style="padding: 0 15px!important;"
                              :headers="configurationHeaders"
                              :items="configurations"
                              :page.sync="pagination.page"
                              :items-per-page.sync="pagination.itemsPerPage"
                              :rows-per-page-items="[10, 25, { text: 'Alle', value: -1 }]"
                              rows-per-page-text="Zeilen pro Seite"
                              @update:page="onPageUpdate"
                              @update:items-per-page="onItemsPerPageUpdate"
                              class="elevation-0 lower-padding"
                >
                  <template slot="items" slot-scope="props">
                    <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.code }}</td>
                    <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.category }}</td>
                    <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.description }}</td>
                    <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.status }}</td>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <div style="flex: 1 1 auto;"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="normalize-font ml-2 mb-2" flat @click.native="cancel">
            Abbrechen
          </v-btn>
          <v-btn
              color="primary"
              class="mr-2 mb-2 normalize-font"
              v-if="!selectedReturnTruck.id"
              @click.native="createReturnTruck"
          >
            Erstellen
          </v-btn>
          <v-btn
              color="primary"
              class="mr-2 mb-2 normalize-font"
              v-else
              @click.native="patchReturnTruck"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {EventBus} from "../../event-bus";

import ImageServices from "../../services/image.service";

import DescriptionValueDisplay from "@/components/DescriptionValueDisplay";

import CONSTANTS from "../../utils/constants"
import HelperFunctions from "@/utils/helper-functions";
import ReturnTruckService from "@/services/return-truck.service";
import ConfigurationService from "@/services/configuration.service";

let fds = new FormData(),
    fDs = [];

export default {
  name: "ReturnTruckForm",
  data() {
    return {
      valid: false,
      customImageMaxSize: 5,
      itemsPerPage: 10,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      uploadStatus: "", // Store upload status
      imageName: "",
      imageFile: "",
      imageRealNames: [],
      imagesFiles: [],
      images: [],
      image: "",
      deleteQueue: [],
      isAdmin: false,
      dialog: false,
      constants: CONSTANTS,
      manufacturers: CONSTANTS.manufacturer,
      statusItems: CONSTANTS.statusItems,
      constructionTypes: CONSTANTS.constructionTypes,
      configurationHeaders: [
        {
          text: "Code",
          align: "left",
          value: "code",
          width: "1%"
        },
        {text: "Kategorie", value: "category", width: "1%"},
        {text: "Beschreibung", value: "description", width: "1%"},
        {text: "Status", value: "status", width: "1%"},
      ],
      configurations: [],
      selectedReturnTruck: {
        manufacturer: "",
        chassisNumber: "",
        secondLifeDecision: "",
        category: "",
        costEstimate: "",
        recordingPerson: "",
        inStock: false,
        status: "",
        description: "",
        constructionYear: "",
        images: [],
        imagesReal: [],
        operatingHours: "",
        cType: ""
      },
      response: "",
      infoColor: "primary",
      rules: {
        required: value => !!value || "Bitte geben Sie einen Wert ein."
      }
    };
  },
  mounted() {
    this.getConstructionYearsSpan();
    this.isAdmin = this.user.isAdmin;
    EventBus.$on("openReturnTruckDialog", this.setDialogStatus);
    EventBus.$on("onSelectReturnTruck", returnTruck => {
      this.loading(true);
      ImageServices.getImagesByReturnTruckId(this.$store.state.accessToken, returnTruck.id
      ).then(response => {
        if (response.data.length > 0) {
          this.images = response.data.filter((img, index) => {
            return index >= 0;
          });
        }
        this.loading(false);
      });
      this.setSelectedReturnTruck(returnTruck);
    });
  },
  methods: {
    ...mapActions(["getConstructionYearsSpan"]),
    ...HelperFunctions,
    pickFile(single) {
      if (single) this.$refs.image.click();
      else this.$refs.images.click();
    },
    onMultipleFilesPicked(e) {
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        fDs.push({file: files[i], name: files[i]["name"]});
      }
      if (files[0] !== undefined) {
        for (const file of files) {
          this.imageRealNames.push(file.name);
          let i = {
            image: file.name,
            url: URL.createObjectURL(file)
          };
          this.selectedReturnTruck.images.push(i);
          if (file.name.lastIndexOf(".") <= 0) {
            return;
          }
        }
      } else {
        this.images = [];
        this.imageRealNames = [];
      }
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (!file) {
        this.uploadStatus = "No files selected.";
        return;
      }

      const fData = new FormData();
      // Append each file to FormData
      fData.append("docxFile", file);
      ReturnTruckService.uploadConfigurations(this.accessToken, this.selectedReturnTruck.id, fData)
          .then(() => {
            this.uploadStatus = "Files uploaded successfully!";
            setTimeout(() => {
              this.uploadStatus = "";
              this.$refs.text.value = "";
            }, 1000);
          })
          .catch(() => {
            this.uploadStatus = "Upload failed.";
          });
    },
    uploadFiles() {
      this.$refs.text.click()
    },
    patchReturnTruck: function (e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        delete this.selectedReturnTruck.createdAt;
        const auth = this.$store.state.accessToken
            ? this.$store.state.accessToken.id
            : null;

        // eslint-disable-next-line
        this.selectedReturnTruck.imagesReal = null;
        this.loading(true);
        this.selectedReturnTruck.updatedAt = new Date(Date.now());
        ReturnTruckService.patchReturnTruckById(
            this.accessToken,
            this.selectedReturnTruck.id,
            this.selectedReturnTruck
        )
            .then(response => {
              if (fDs.length > 0) {
                for (let i = 0; i < fDs.length; i++) {
                  fds.append("images", fDs[i].file, fDs[i].name);
                  this.resizeImageAndUpload(auth, response.data.id, fDs[i].file, fDs[i].name, "rTruck");
                }
              }
            })
            .then(() => {
              this.deleteQueue.forEach(item => {
                // remove all unused images
                const image = this.images.find(i => i.imageId === item);
                // ImageServices.deleteImageById(auth, item);
                ImageServices.deleteRelatedImages(auth, "truck", image.returnTruckId, item);
              });
              this.setDialogStatus();
            })
            .then(() => {
              this.initiateSuccessInfo("Return Truck update erfolgreich", true)
              EventBus.$emit("onCloseReturnTruckDialog", true);
              this.loading(false);
            })
            .catch(() => {
              this.initiateErrorInfo("Bitte kontrollieren!")
              this.loading(false);
            });
      } else {
        this.initiateErrorInfo("Bitte kontrollieren!")
      }
    },
    createReturnTruck(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        const auth = this.$store.state.accessToken
            ? this.$store.state.accessToken.id
            : null;
        // eslint-disable-next-line
        this.selectedReturnTruck.image = null;
        this.selectedReturnTruck.imagesReal = null;
        this.loading(true);
        ReturnTruckService.addReturnTruck(this.accessToken, this.selectedReturnTruck)
            .then(response => {
              if (fDs.length > 0) {
                for (let i = 0; i < fDs.length; i++) {
                  fds.append("images", fDs[i].file, fDs[i].name);
                  this.resizeImageAndUpload(auth, response.data.id, fDs[i].file, fDs[i].name, "rTruck");
                }
              }
              this.setDialogStatus();
            })
            .then(() => {
              this.initiateSuccessInfo("Return Truck wurde erstellt", true)
              EventBus.$emit("onCloseReturnTruckDialog", true);
              this.$store.commit("loading", false);
            })
            .catch(() => {
              this.initiateErrorInfo("Bitte Eingaben kontrollieren!")
              this.$store.commit("loading", false);
            });
      } else {
        this.initiateErrorInfo("Bitte Eingaben kontrollieren!")
        this.$store.commit("loading", false);
      }
    },
    setDialogStatus() {
      this.deleteQueue = [];
      this.dialog = !this.dialog;
    },
    cancel() {
      this.resetForm();
      this.resetValidation();
      this.setDialogStatus();
    },
    setSelectedReturnTruck(returnTruck) {
      fds = new FormData();
      fDs = [];
      this.selectedReturnTruck = "";
      this.selectedReturnTruck = returnTruck;
      if (returnTruck) {
        ConfigurationService.getConfigurationsByReturnTruckId(this.accessToken, returnTruck.id)
            .then(response => {
              this.configurations = response.data;
              this.selectedReturnTruck.images = this.selectedReturnTruck.images || [];
              this.loading(false);
            });
      }
    },
    removeImageFromList(imageId, index, name) {
      if (imageId) {
        this.deleteQueue.push(imageId);
      }

      const indexToRemove = fDs.findIndex(img => img.name === name.image);
      fDs.splice(indexToRemove, 1);
      this.selectedReturnTruck.images.splice(index, 1);
    },
    removeProductImage(imageId) {
      if (imageId) {
        this.deleteQueue.push(imageId);
      }
      this.imageName = "";
      this.imageFile = "";
      this.selectedReturnTruck.image = "";
    },
    resetForm() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onPageUpdate(newPage) {
      this.pagination.page = newPage;
    },
    onItemsPerPageUpdate(newItemsPerPage) {
      this.pagination.itemsPerPage = newItemsPerPage;
    }
  },
  computed: {
    ...mapState([
      "accessToken",
      "user"
    ]),
    constructionYear: {
      get: function () {
        if (this.selectedReturnTruck.constructionYear)
          return Number(this.selectedReturnTruck.constructionYear);
      },
      set: function (newValue) {
        this.selectedReturnTruck.constructionYear = newValue;
      }
    }
  },
  components: {
    DescriptionValueDisplay,
  }
};
</script>

<style>
#return-truck-form .v-chip,
#return-truck-form .v-chip__content {
  color: white;
  background: #b80c1e;
}

#return-truck-form .v-chip {
  background: #b80c1e;
}
</style>
