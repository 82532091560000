<template>
  <v-container class="pa-0 ma-0 mr-0" style="max-width:100%;">
    <v-layout id="returnT-truck-manager">
      <v-flex xs12 class="pa-0 ma-0 mr-0">
        <v-layout text-xs-right v-if="isAdmin">
          <v-flex xs12 class="pa-0 ma-2 mr-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
            <v-btn
                small
                dark
                fab
                top
                icon
                right
                color="primary"
                @click="addReturnTruck"
                v-on="on"
            >
              <v-icon style="display: inline-flex">add</v-icon>
            </v-btn>
              </template>
              <span>Erstellen</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
        <v-data-table
            style="padding: 0 15px!important;"
            :headers="headers"
            :items="returnTrucks"
            :pagination.sync="pagination"
            :rows-per-page-items="[5, 10, 25, { text: 'Alle', value: -1 }]"
            rows-per-page-text="Zeilen pro Seite"
            class="elevation-0 lower-padding"
        >
          <template slot="items" slot-scope="props">
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.id }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.createdAt | formatDate }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.updatedAt | formatDate }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.chassisNumber }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.manufacturer }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.cType }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.operatingHours }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.constructionYear }}</td>
            <td class="text-xs-left text-md-left" style="padding: 0 15px!important;">
              <v-layout v-if="isAdmin">
                <v-flex>
                  <v-btn
                      right
                      small
                      icon
                      flat
                      @click="editReturnTruck(props.item.id)"
                      color="primary"
                  >
                    <v-icon small>edit</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex>
                  <v-btn
                      right
                      small
                      icon
                      flat
                      @click="deleteReturnTruckById(props.item.id)"
                      color="error"
                  >
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout v-else>
                <v-flex>
                  <v-btn
                      right
                      small
                      icon
                      flat
                      @click="editReturnTruck(props.item.id)"
                      color="primary"
                  >
                    <v-icon small>description</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </td>
          </template>
        </v-data-table>
        <!-- DELETE DIALOG -->
        <DeleteReturnTruckDialog></DeleteReturnTruckDialog>
        <!-- EDIT DIALOG -->
        <ReturnTruckForm
            v-model="dialog"
            :equipment="selectedReturnTruck"
            :showDialog="dialog"
        ></ReturnTruckForm>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import ReturnTruckForm from "@/components/_returnTrucks/ReturnTruckForm.vue";
import ReturnTruckService from "@/services/return-truck.service";
import {EventBus} from "../../event-bus";
import HelperFunctions from "@/utils/helper-functions";
import DeleteReturnTruckDialog from "@/components/_returnTrucks/DeleteReturnTruckDialog.vue";
export default {
  name: "ReturnTruckManagement",
  data: function () {
    return {
      pagination: {
        sortBy: "id",
        rowsPerPage: 10
      },
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "id",
          width: "1%"
        },
        {text: "Erstelldatum", value: "createdAt", width: "1%"},
        {text: "Aktualisiert", value: "updatedAt", width: "1%"},
        {text: "Fahrgestell-Nr.", value: "serialNumber", width: "1%"},
        {text: "Hersteller", value: "manufacturer", width: "1%"},
        {text: "Typen Beschreibung", value: "type", width: "1%"},
        {text: "Betriebstunden", value: "operatingHours", width: "1%"},
        {text: "Baujahr", value: "constructionYear", sortable: true, width: "1%"},
        {text: "", value: "actions", sortable: false, width: "1%"}
      ],
      dialogStatus: false,
      deleteDialog: false,
      importDialog: false,
      reservedDialog: false,
      returnTruckId: null,
      returnTruck: null,
      isAdmin: false,
      selectedReturnTruck: null,
      returnTrucks: [],
      file: "",
      response: "",
      infoColor: "primary",
    };
  },
  components: {
    DeleteReturnTruckDialog,
    ReturnTruckForm,
  },
  mounted() {
    this.isAdmin = this.user.isAdmin;
    this.reloadReturnTrucksList();
    EventBus.$on("onCloseReturnTruckDialog", () => {
      this.selectedReturnTruck = null;
      this.reloadReturnTrucksList();
    });
  },
  computed: {
    ...mapState(["accessToken", "user"]),
    dialog: {
      get: function () {
        this.reloadReturnTrucksList();
        return this.dialogStatus;
      },
      set: function (value) {
        this.reloadReturnTrucksList();
        this.dialogStatus = value;
      }
    }
  },
  methods: {
    ...HelperFunctions,
    reloadReturnTrucksList() {
      ReturnTruckService.getReturnTrucks(this.accessToken).then(response => {
        this.returnTrucks = response.data;
      })
    },
    addReturnTruck() {
      EventBus.$emit("openReturnTruckDialog", true);
      EventBus.$emit("onSelectReturnTruck", this.getEmptyReturnTruck());
    },
    editReturnTruck(id) {
      ReturnTruckService.getReturnTruckById(this.accessToken, id).then(response => {
        EventBus.$emit("openReturnTruckDialog", true);
        EventBus.$emit("onSelectReturnTruck", response.data);
      })
    },
    deleteReturnTruckById(id) {
      ReturnTruckService.getReturnTruckById(this.accessToken, id).then(response => {
        EventBus.$emit("openDeleteReturnTruckDialog", response.data);
      });
    }
  }
};
</script>

<style scoped>
table.v-table thead td, table.v-table thead th {
  padding: 0 14px !important;
}
</style>
