<template>
  <v-dialog v-model="dialog" max-width="300">
    <v-card>
      <v-toolbar dark color="primary px-3">
        <v-toolbar-title>Return Truck löschen</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p class="subheading">
          Möchten Sie diesen Return Truck löschen?
        </p>
        <span class="subheading linde-color linde-font">
                {{ cType ? cType : "" }}
              </span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="normalize-font mb-2"
            flat
            @click="onCancel"
        >
          Abbrechen
        </v-btn>
        <v-btn
            class="normalize-font mb-2 mr-2"
            color="primary"
            @click="deleteReturnTruck()"
        >
          Löschen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import ReturnTruckService from "@/services/return-truck.service";
import {EventBus} from "@/event-bus";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "DeleteReturnTruckDialog",
  data: function () {
    return {
      dialog: false,
      id: "",
      cType: "",
      operation: ""
    }
  },
  props: {},
  mounted() {
    EventBus.$on("openDeleteReturnTruckDialog", (data) => {
      this.dialog = true
      this.id = data.id
      this.cType = data.cType
    })
  },
  computed: {
    ...mapState(["accessToken"]),
  },
  methods: {
    ...HelperFunctions,
    deleteReturnTruck() {
      this.loading(true);
      ReturnTruckService.deleteReturnTruckById(this.accessToken, this.id)
          .then(() => {
            this.initiateSuccessInfo("Return Truck wurde gelöscht.", true)
            this.loading(false);
            EventBus.$emit("onCloseReturnTruckDialog", true);
            this.onCancel()
          })
          .catch(() => {
            this.initiateErrorInfo( "Ein Fehler ist aufgetreten! Bitte versuchen Sie es später erneut.")
            this.loading(false);
            EventBus.$emit("onCloseReturnTruckDialog", true);
          });
    },
    onCancel() {
      this.dialog = false
      this.returnTruck = null
      this.id = ""
      this.type = ""
    },
  }
};
</script>
