import Api from "./api";

const RESOURCE_NAME = "Configurations";

const getConfigurationsByReturnTruckId = (auth, id) => {
    return Api().get(RESOURCE_NAME + `/return-trucks/` + id, {
        headers: {
            Authorization: returnAccessToken()
        }
    });
};

function returnAccessToken(auth) {
    return auth ? auth.id : null;
}

export default {
    getConfigurationsByReturnTruckId,
};
